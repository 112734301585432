import axios from "axios";

const api_url =
  process.env.NODE_ENV === "production"
    ? "http://login.emerge.lk:9090"
    : "http://login.emerge.lk:9090";

const new_APIURL = "http://codebrix-sys.xyz:7031/api/v1/";
// const new_APIURL = "https://localhost:44391/api/v1/";
// const new_APIURL = "http://admin.myportersa.com/myporteriis1/api/v1/";
class UserService {
  userRegistration(user) {
    return axios.post(new_APIURL + "system-user/register", user);
  }

  login(user) {
    return axios.post(new_APIURL + "system-user/login", user);
  }

  forgetPassword(email) {
    return axios.post(new_APIURL + `system-user/send-otp?email=${email}`);
  }
  resetPassword(email, password) {
    return axios.post(
      new_APIURL +
        `system-user/forget-password?email=${email}&newPassword=${password}`
    );
  }

  getUserDetails(userId) {
    return axios.get(
      new_APIURL + `system-user/user-details-by-id?userId=${userId}`
    );
  }

  updateUserProfile(profile, userId) {
    return axios.put(new_APIURL + `system-user/${userId}`, profile);
  }

  validReciveCode(email, otp, isForgetPassword) {
    return axios.get(
      new_APIURL +
        `system-user/validate-otp?email=${email}&otp=${otp}&isForgetPassword=${isForgetPassword}`
    );
  }

  sendOtp(email) {
    return axios.post(new_APIURL + `system-user/send-otp?email=${email}`);
  }

  reSendOtp(email) {
    return axios.post(new_APIURL + `system-user/resend-otp?email=${email}`);
  }
  updateCustomer(updateType, data) {
    return axios.post(
      api_url + "/MyPorterAPI/api/Auth/UpdateCustomer?updateType=" + updateType,
      data
    );
  }

  changePassword(userId, oldPassword, newPassword) {
    return axios.post(
      new_APIURL +
        `system-user/change-password?userId=${userId}&oldPassword=${oldPassword}&newPassword=${newPassword}`
    );
  }

  sendSMSOtp(contactNumber) {
    return axios.post(
      new_APIURL + `system-user/send-sms-otp?number=${contactNumber}`
    );
  }

  validateSMSOTP(contactNumber, otp, isSMSSend) {
    return axios.get(
      new_APIURL +
        `system-user/validate-sms-otp?contactNumber=${contactNumber}&otp=${otp}&isSMSSend=${isSMSSend}`
    );
  }
}

export default new UserService();
